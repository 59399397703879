import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService, OAuthErrorEvent, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig, environment } from "../../environments/environment"
import { MechanicsService } from './mechanics.service';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

@Injectable({
	providedIn: 'root'
})

export class AuthService {

	constructor(private oauthService: OAuthService,
		public ms: MechanicsService,
		public router: Router,
		public http: HttpClient,
	) { }


	async doSSO(): Promise<string> {

		const l = `AuthService doSSO() - `
		console.log(`${l}router.url = ` , this.router.url)


		// https://www.npmjs.com/package/angular-oauth2-oidc
		this.oauthService.setupAutomaticSilentRefresh();
		this.oauthService.events.subscribe(event => {
			if (event instanceof OAuthErrorEvent) {
			  console.error('wipoAuthService: OAuthErrorEvent:', event);
			} else if (event instanceof OAuthSuccessEvent && event.type === 'silently_refreshed') {
			  console.log(`wipoAuthService: OAuthEvent Access token REFRESHED, valid until: ${new Date(this.oauthService.getAccessTokenExpiration())}`);
			}
		  });
		this.oauthService.configure(authConfig);
		await this.oauthService.loadDiscoveryDocumentAndTryLogin()
		const claims = this.oauthService.getIdentityClaims() as any;
		if (claims && claims.name) {
			console.log('wipoAuthService: identity claims present', claims);
		} else {
			this.oauthService.initImplicitFlow();
		}
		
		// check if user is logged in 
		console.log(`${l}Starting userid get`)
		let state:string = this.oauthService.state // user just logged in and the state is stored in the initImplicitFlow
			? decodeURIComponent(this.oauthService.state)
			: window.location.pathname + window.location.search

		console.log(`${l}state = `, state)
		return state
	}

	
	  private cleanOidcSessionStorage() {
		sessionStorage.removeItem('access_token');
		sessionStorage.removeItem('id_token');
		sessionStorage.removeItem('refresh_token');
		sessionStorage.removeItem('nonce');
		sessionStorage.removeItem('expires_at');
		sessionStorage.removeItem('id_token_claims_obj');
		sessionStorage.removeItem('id_token_expires_at');
		sessionStorage.removeItem('id_token_stored_at');
		sessionStorage.removeItem('access_token_stored_at');
		sessionStorage.removeItem('granted_scopes');
		sessionStorage.removeItem('session_state');
	  }

		get hasValidToken() { return this.oauthService.hasValidIdToken(); }
		get accessToken() { return this.oauthService.getAccessToken(); }
		get identityClaims() { return this.oauthService.getIdentityClaims(); }
		get idToken() { return this.oauthService.getIdToken(); }
	 
		/*
		getUserDetails() {
			// console.log('xxxxxxxxxx getUserDetails')
			// console.log('accessToken', this.accessToken)
			// console.log('identityClaims', this.identityClaims)
			// console.log('idToken', this.idToken)
			// console.log('accessToken decoded', jwt_decode(this.accessToken))
		}
	*/
}

