import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { menu_items } from './_imports/menu';

const allKeys = Object.keys(menu_items);
const lastVersion = allKeys[allKeys.length-1]
const firstDoc = menu_items[lastVersion]['Documentation'][0]
const redirec_url: string = '/'+ lastVersion + '/docs/' + firstDoc	



const routes: Routes = [


	{ path: ':version/docs/:file', 
	  loadChildren: async () => (await import("./pages/documentation/documentation.module")).DocumentationModule

	},
	{ path: ':version/clients/:type/:file', 
	loadChildren: async () => (await import("./pages/documentation/documentation.module")).DocumentationModule

  	},
	{ path: '',  redirectTo: redirec_url, pathMatch: 'full' },
	{ path: 'logout', redirectTo: "/", pathMatch: 'full' },

	{
		path: '**',
		loadChildren: async () => (await import("./pages/page-notfound/page-notfound.module")).PageNotfoundModule
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		enableTracing: false,
		initialNavigation: 'disabled' // initial navigation happens after doSSO() in auth.service
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
