/*
	MechanicsService is the lowest level service there is. Because it is injected in every other component and service, it must not import anything else than Angular core stuf (this would create a dependency loop).
*/

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { environment } from '../../environments/environment'
import packagejson from '../../../package.json';
import { WOption } from '@wipo/w-angular/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { resizeImage } from '../utils';

const rename_special = {
	'by.brandName': 'inids.name',
}

const localesMapping = { // for Intl.DateTimeFormat and language switching
	en: "en-US"
}


@Injectable({
	providedIn: 'root'
})
export class MechanicsService {

	// General variables
	public isLoading: boolean = false;
	private isLoadingTimeout: any = null;

	public environment: any;

	public appVersion: string = `v${packagejson.version}`;

	public isLocalHost: boolean = environment.env.toLowerCase() === 'localhost';
	public isBeta: boolean = false
	public isAwsProd: boolean = environment.env.toLowerCase() === 'awsprod';
	public isAwsAcc: boolean = environment.env.toLowerCase() === 'awsacc';
	public isAwsDev: boolean = environment.env.toLowerCase() === 'awsdev';

	public endpoint: string = null

	public supressFacets: boolean = false

	public docs_to_load: string = null 
	public version:string = null
	// Language
	private availableLangs: string[] = Object.keys(localesMapping);
	public lang: string
	public translations: JSON;

	public dateFormatterHuman; // using the native window.Intl.DateTimeFormat. Init in switchLang(). Use with : const formattedDate:string = dateFormatterHuman.format(date:Date);

	// Same. Use with : const formattedDate:string = dateFormatterISO.format(date:Date);
	public dateFormatterISO = new Intl.DateTimeFormat("en-CA",
		{ // "2022-02-01"
			year: "numeric",
			month: "numeric",
			day: "numeric"
		});

	public numberFormatter; // Same. Use with : const formattedNumber:string = numberFormatter.format(num:Number);

	public graphsRange = {
		// { applicationDate: [from, to] }
		range: {},

		add: function (field: string, start: string, end: string): void {
			this.range[field] = [start, end]
		},

		reset: function (field: string = '*'): void {
			if (field !== '*') delete this.range[field]
			else this.range = {}
		},

		contains: function (field: string): boolean {
			return (this.range[field] || []).length
		}
	}

	public graphsSelection = {
		// { niceClass: ['1','2','3'], designation: ['AA', 'BB'] }
		selection: {},

		state: function (field: string, value: string | number): boolean {
			value = value + ""
			return (this.selection[field] || []).includes(value)
		},

		count: function (field?: string): number {
			return (this.selection[field] || []).length
		},

		add: function (field: string, value: string | number): void {
			this.selection[field] = (this.selection[field] || [])
			this.selection[field].push(value + '')
		},

		remove: function (field: string, value: string | number): void {
			this.selection[field] = (this.selection[field] || []).filter(v => v !== value + '')
		},

		toggle: function (field: string, value: string | number): void {
			if (this.state(field, value)) this.remove(field, value)
			else this.add(field, value)
		},

		reset: function (field: string = '*'): void {
			if (field !== '*') delete this.selection[field]
			else this.selection = {}
		},

		contains: function (field: string): boolean {
			return this.count(field) > 0
		}
	}

	public tooltips = {
		addedToReports: false
	}

	public statuses: WOption[] = [];

	public coverageDataCache: any;

	public activeBrickId: string | null = null; // Used to raise the z-index of the clicked brick in advancedSearch, otherwise Primeng's calendar is below other bricks

	constructor(public ts: TranslateService,
		public activatedRoute: ActivatedRoute,
		public router: Router) {

		const l: string = `MS constructor - `

		/*
			if (this.isAwsProd) {
				window.console.log = function () { }
				window.console.warn = function () { }
			}
		*/

		environment.env = (environment.env || "").toLowerCase();

		this.environment = environment;

		this.environment.appLangs = this.availableLangs
			.map((lang: string) => ({
				code: lang,
				link: this.environment.appUrl + `/${lang}`
			}));

		this.detectEndpoint()

		// console.log(`${l}bases64 from sessionStorage = `, this.bases64);

		// Extracting the full translations object from Angular translate service :) Cool
		this.ts.onLangChange.subscribe((event: TranslationChangeEvent) => {
			// console.log(`${l}Angular TranslationChangeEvent = `, event);
			this.translations = event.translations
			// console.info(`${l}Set ms.translations = `, this.translations);

		});

	}

	_rename_for_special_collection(root: any, needle: string, replace: string) {
		for (let key in root) {
			if (typeof root[key] == 'string') {
				if (root[key].includes(needle)) {
					root[key] = root[key].replace(needle, replace)
				}
			}
			else {
				this._rename_for_special_collection(root[key], needle, replace)
			}
		}
	}

	initDateFormatter(locale?: string): void { // 'en-US'

		const l = `ms.initDateFormatter - `

		// console.log(`${l}Passed locale = `, locale)

		locale = locale || new Intl.NumberFormat().resolvedOptions().locale; // 'en-US'

		// console.log(`${l}Using locale = `, locale)

		this.dateFormatterHuman = new Intl.DateTimeFormat(locale, // If this was in utils.ts, the locale won't update after init. For the locale to be dynamic, this needs to be in a service
			{ // "November 27, 2019"
				timeZone: "Europe/London", // 2023-02-15 problems with Seattle users who see dates the day before, attempting to force Europe timezone
				year: "numeric",
				month: "long",
				day: "numeric"
			});
	}

	initNumberFormatter(locale?: string): void {

		locale = locale || new Intl.NumberFormat().resolvedOptions().locale;

		this.numberFormatter = new Intl.NumberFormat(locale); // Default options are good for decimal formatting. 123456 --> '123,456' or '123 456' (returns a string)
	}

	get isMobileView(): boolean {
		return window.innerWidth <= 800
	}
	

	detectEndpoint() {

		const l: string = `MS detectEndpoint() - `

		// For some reason, Angular's ActivatedRoute is empty at startup (??) so I'm simply using the good old window.location

		// console.log(`${l}ActivatedRoute.url subscription : trying to detect endpoint from window.location.pathname='${window.location.pathname}'`);

		if (window.location.pathname === "/") {
			// console.log(`${l}'this.endpoint' is "/", the page is probably still loading. Skipping for now`);
			return []
		}

		const errMsg: string = `${l}Could not work out the endpoint from URL : '${window.location.pathname}'. Expecting '/:lang/:endpoint'`;

		try {
			this.endpoint = window.location.pathname.split("/")[1]
			// console.log(`${l}Found endpoint : '${this.endpoint}'`);
		} catch (err) {
			console.error(errMsg);
		}

		if (!this.endpoint) {
			console.error(errMsg);
		}

		 console.log(`${l}Found endpoint='${this.endpoint}'`)
	}


	makeRoute({ path = this.endpoint, subpath = '', includeOffice = true, office, caller }: { path?: string, subpath?: string, includeOffice?: boolean, office?: string, caller?: string }): string {

		const l = `ms.makeroute() - `

		// console.log(`${l}caller='${caller}'`)

		let parts: string[] = ['']
	
		parts.push(path)

		if (subpath) {
			parts.push(subpath)
		}

		const route = parts.join('/');

		// console.log(`${l}route = '${route}'`)

		return route
	}


	setLoading(state: boolean = true, caller?: string) {

		const l: string = `MS setLoading() - caller = '${caller}' - `

		/*
			Manages the "processing" UI blocker. Displays it only after 1 second so it's not too intrusive
		*/

		// console.log(`${l}state='${state}' this.isLoadingTimeout='${this.isLoadingTimeout}'`);

		if (state && this.isLoadingTimeout) {
			// Some function asked to display the "is processing" blocker, but there's already a timeout in the process (like, another function asked the same thing 0.5s earlier) so I'm just ignoring this request.
			// console.log(`${l}UI blocker is already scheduled to appear. Ignoring this request.`);
			return
		}

		if (state) {

			const delay: number = 800
			// console.log(`${l}Scheduling UI blocker in ${delay}ms...`);

			this.isLoadingTimeout = setTimeout(() => {
				// console.log(`${l}Displaying UI blocker.`)
				this.isLoading = true
			}, delay)

		} else {

			// console.log(`${l}Clearing UI blocker.`);
			clearTimeout(this.isLoadingTimeout);
			this.isLoadingTimeout = null
			this.isLoading = false;
		}

	}


	dateToHuman(dateString: string): string {

		const l = `ms.dateToHuman() - `

		// console.log(`\n\n${l}formatting : ${dateString}`)

		if (!dateString) return ''

		// Dates can be "2010-03-02T23:59:59Z" or "1608940799000"

		try {

			let dateShort: string

			if (/^\d{13}$/.test(dateString)) { // "1608940799000" 
				dateShort = new Date(+dateString).toISOString() // '2020-12-25T23:59:59.000Z'
			}

			/*
				Problem : 2012-01-02T23:59:59Z is converted to "January 3" despite the date being January 2.
				It's also converted the day before in other timezones.
				
				I'm removing the T23:59:59Z part. Without any hour nor timezone indication, the day should never be wrong
			*/

			dateShort = dateString.split("T")[0];

			// console.log(`${l}dateShort='${dateShort}'`)

			const newDate: Date = new Date(dateShort)

			// console.log(`${l}newDate.toISOstring() = `, newDate.toISOString())

			const toReturn = this.dateFormatterHuman.format(newDate);

			// console.log(`${l}formatted = `, toReturn)

			return toReturn

		} catch (err) {

			// console.log(`${l}Caught error formatting date '${dateString}' : `, err.message || err)
			return ""
		}
	}

	dateToIso(dateString: string): string {
		if (!dateString) return ''

		// Dates can be "2010-03-02T23:59:59Z" or "1608940799000"

		let date: Date;

		if (/\d{13}/.test(dateString)) {
			date = new Date(+dateString); // "1608940799000" --> "2020-12-25T23:59:59.000Z"
		} else {
			date = new Date(dateString)
		}

		return this.dateFormatterISO.format(date);
	}

	translate(word: string): string {

		const l: string = `ms.translate() - `
		// console.log(`${l}translating ${word}`);

		/*
			Normally, in templates, the | translate pipe is used.
			But from Typescript code, I can just use the JSON language file (which is extracted from the TranslateService in the constructor of MS).
		*/

		if (!this.translations) {
			// console.log(`${l}Translations object hasn't loaded yet [${word}] - this = `, this);
			// It's async, the translations object hasn't loaded yet
			return "⧗";
		}

		// multilevel support for translation json: level1.level2.level3.level...
		let translation: any = this.translations,
			split = word.split('.');

		try {
			split.forEach(key => { // key = 'status' then 'Expired'; 'page_visu' then 'graphing'; etc. It allows to drill down
				if (translation) {
					translation = translation[key]; // this drills down in the i18n JSON file, clever
				}
			})
		} catch (err) {
			// console.log(l, err)
		}

		/*
			If the translation cannot be found, we return split[0].

			In the case of "applicant.hyundai motor company" it will return "hyundai motor company"
		*/

		// return translation even if it is set to ""
		if (translation !== undefined) {
			// console.log(`${l}Returning '${word}' translated in '${this.lang}' language = ${translation}`)	
			return translation
		}

		// console.log(`${l}Could not translate '${word}'`)
		return word
	}

	async waitForTranslations(): Promise<string> {

		const l = `MS waitforTranslations()`

		// Utility that makes sure translation object is loaded and you can use ms.translate()

		while (!this.translations) {
			// console.log(`${l}Translations not yet ready...`)
			await new Promise(r => setTimeout(r, 100))
		}

		return "ok"
	}
}
