import { ChangeDetectorRef, Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from "../environments/environment"
import { AuthService } from './_services/auth.service';
import { MechanicsService } from './_services/mechanics.service';
import { WOption } from '@wipo/w-angular/shared';



declare const SmartlingContextTracker

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

	public environment = environment

	public logsSettings: any = {
		isExists: !this.ms.isAwsProd,
		align: "left"
	}

	public helpOptions: any[] = [];
	public helpMore: any[] = [];
	public isOpen = true;
	
	constructor(
		private router: Router,
		public ms: MechanicsService,
		private authService: AuthService,
		private changeDetector: ChangeDetectorRef) {

	}

	async ngOnInit() {

		const l = `app.component ngOnInit() - `

		if (this.ms.isAwsAcc) this.loadSmartlingContext() // only on AWS acceptance

		let state: string = window.location.pathname + window.location.search;
		
		this.buildHelpMenu()

		state = await this.authService.doSSO(); // Too many errors in the console, I'm cleaning this up
		this.loadWipoNavbar()


		this.router.navigateByUrl(state, {
			skipLocationChange: false,
			replaceUrl: true
		})

		const logsSettingsString = localStorage.getItem("gbd.logsSettings");

		if (logsSettingsString) {

			try {
				let logsSettings = JSON.parse(logsSettingsString);
				this.logsSettings = Object.assign(this.logsSettings, logsSettings)
				// console.log(`${l}this.logsSettings=`,this.logsSettings)

			} catch (err) {
				// console.log(`${l}Could not restore logs settings, ignoring - Full err =`, err)
			}
		}
	}

	ngAfterViewChecked() {

		/*
			While in development, the console can get filled with very annoying "error" messages saying that some variable has changed since last time it was checked (??)
			Some Angular weird stuff.
			
			Apparently, calling changeDetector.detectChanges() prevents those.
			
			It doesn't happen in prod build, so I'm only doing it in dev


			--> Update 2022-11-03 : Extremely tenacious bug I've spent hours to chase.
			on localhost and awsDev, all was working fine. But in acc and uat, we were only getting blank pages and unrefreshed templates. Uncommenting this and leaving changeDetector.detectChanges() in all environment seems to fix the problem. So LEAVE THIS ON!
		*/

		this.changeDetector.detectChanges();
	}


	async buildHelpMenu() {

		const l = `appcomponent buildHelpMenu() - `
		let antiloop = 0
		// console.log(`${l}Rebuilding help menu`)
		while (!this.ms.translations && antiloop < 50) {
			await new Promise(r => setTimeout(r, 200));
			antiloop++;
		}

		this.helpOptions = [  // Optional, set custom links for Contact and FAQ  (default = WIPO CONTACT)
			{
				"code": "contact", // - code property is mandatory
				"link": `https://www3.wipo.int/contact/${this.ms.lang}/area.jsp?area=branddb` // - link property is optional
			},
			{
				"name": "FAQs",
				"code": "faq",
				"link": environment.FAQUrl.replace("{lang}", this.ms.lang),
				"targetTab": "_self"
			},
		];

		this.helpMore = [
			{
				"name": this.ms.translate("help_menu.terms_of_use"),
				"code": "terms",
				"link": `https://www.wipo.int/reference/${this.ms.lang}/branddb/terms_and_conditions.html`,
				"targetTab": "_self"
			},

		];
	}

	loadWipoNavbar(): void {

		const l = `appcomponent loadWipoNavbar() - `

		const wipoComponentsPath: string = environment.wipoComponentsPath;

		if (!wipoComponentsPath) {
			// console.log(`${l}No wipoComponentsPath, not loading the WIPO navbar`)
			return
		}

		// console.log(`${l}wipoComponentsPath : `, wipoComponentsPath);

		[
			`${wipoComponentsPath}/polyfills/webcomponents-loader.js`,
			`${wipoComponentsPath}/wipo-init/wipo-init.js`,
			`${wipoComponentsPath}/wipo-navbar/wipo-navbar.js`
		].forEach(src => {
			document.head.appendChild(Object.assign(document.createElement('script'), {
				src,
				async: true
			}));
		});
	};


	loadSmartlingContext() {
		var script = document.createElement('script');
		script.onload = function () {
			SmartlingContextTracker.init({
				orgId: 'LR3HwdNcfBVS269bS54AUQ',
				host: 'api.smartling.com'
			});
		};
		script.src = "//d2c7xlmseob604.cloudfront.net/tracker.min.js";
		document.getElementsByTagName('head')[0].appendChild(script);
	}

	get windowWidth(): number {
		return window.innerWidth
	}

	setLogsSetting(key: string, value: any) {

		const l = `appComponent setLogsSetting() - `

		// console.log(`${l}setting logsSettings[${key}] = ${value}`)

		this.logsSettings[key] = value;

		localStorage.setItem("gbd.logsSettings", JSON.stringify(this.logsSettings))
	}

	toggleDummyLogos($event) {

		const l = `compResultsInfo toggleDummyLogos() - `

		const isChecked: boolean = $event.target.checked

		// console.log(`${l}isCheckd = `, isChecked)

		localStorage.setItem("showDummyImages", "" + isChecked)

	}

	get isDummyLogos() {
		return localStorage.getItem("showDummyImages") === "true"
	}
}
