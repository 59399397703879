import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MechanicsService } from 'src/app/_services/mechanics.service';

@Component({
	selector: 'documentation',
	templateUrl: './documentation.component.html',
	styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {

	constructor(
		private router: Router,
		public ms:MechanicsService
	) {
		console.log("AICI", this.router.url)
		if(!this.ms.version)
			this.ms.version = this.router.url.split('/')[1];
		if(!this.ms.docs_to_load)
			this.ms.docs_to_load = this.router.url
	}
	ngOnInit(): void {
	}

}
