

// ANGULAR CORE

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


// ENVIRONMENT

import { environment } from "../environments/environment"


// CACHE BUSTING

import cacheBusting from '../../assets-cache-busting.json';

// TRANSLATION

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// https://github.com/ngx-translate/core
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n-jer/', '.json?_=' + cacheBusting['i18n']);
}


// AUTH

import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
export function oAuthStorageFactory(): OAuthStorage { return localStorage; } // use localStorage instead of sessionStorage


// PLUGINS

import { AngularDraggableModule } from 'angular2-draggable';
import { ClickOutsideModule } from 'ng-click-outside';
import { TippyModule, tooltipVariation, popperVariation } from '@ngneat/helipopper';
import { MarkdownModule } from 'ngx-markdown';

// PAGES COMPONENTS

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './pages/page-notfound/page-notfound.component';
import { DocumentationComponent } from './pages/documentation/documentation.component';

import {CompFacetsSearch } from './_components/facets-search/facets-search.component';

// HTTP INTERCEPTOR (mainly for Giacomo... Not used?)

//import { AwsInterceptInterceptor } from './_services/aws-intercept.interceptor';



// W-ANGULAR AND PRIMENG

import {
	FFacetModule,
	WActionBarModule,
	WBlockUiModule,
	WButtonGroupModule,
	WButtonModule,
	WDrawerModule,
	WEditPanelModule,
	WFieldModule,
	WFilterModule,
	WInputAutocompleteModule,
	WInputBadgeModule,
	WInputCheckboxManyModule,
	WInputCheckboxOneModule,
	WInputRadioModule,
	WInputTextModule,
	WNotificationBarModule,
	WPageHeaderModule,
	WPageSectionModule,
	WSectionModule,
	WSelectManyModule,
	WSelectOneModule,
	WSidebarModule,
	WSlotModule,
	WStepModule,
	WStickyBarModule,
	WTableCommandsModule,
	WValidationPanelModule,
	WViewPanelModule,
	WDialogModule,
	WSideMenuModule
} from '@wipo/w-angular';

import { DataViewModule } from 'primeng/dataview';
import { WInputDateModule } from '@wipo/w-angular/primeng';

@NgModule({
	declarations: [
		AppComponent,
		CompFacetsSearch,
		PageNotFoundComponent,
		DocumentationComponent

	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,

		FFacetModule,
		WActionBarModule,
		WBlockUiModule,
		WButtonGroupModule,
		WButtonModule,
		WDrawerModule,
		WEditPanelModule,
		WFieldModule,
		WFilterModule,
		WInputAutocompleteModule,
		WInputBadgeModule,
		WInputCheckboxManyModule,
		WInputCheckboxOneModule,
		WInputDateModule,
		WInputRadioModule,
		WInputTextModule,
		WNotificationBarModule,
		WPageHeaderModule,
		WPageSectionModule,
		WSectionModule,
		WSelectManyModule,
		WSelectOneModule,
		WSidebarModule,
		WSlotModule,
		WStepModule,
		WStickyBarModule,
		WTableCommandsModule,
		WValidationPanelModule,
		WSideMenuModule,
		WViewPanelModule,
		WDialogModule,
		ClickOutsideModule,

		DataViewModule,

		// https://github.com/ngx-translate/core
		TranslateModule.forRoot({

			defaultLanguage: 'en',

			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),

		MarkdownModule.forRoot(),

		OAuthModule.forRoot({
			resourceServer: {
				allowedUrls: [
					environment.backendUrl
				],
				sendAccessToken: true
			}
		}),

		TippyModule.forRoot({
			defaultVariation: 'tooltip',
			variations: {
				tooltip: tooltipVariation,
				popper: popperVariation,
			}
		})
	],
	providers: [
		/*{
			provide: HTTP_INTERCEPTORS,
			useClass: AwsInterceptInterceptor,
			multi: true // false completely breaks the app, for some reason
		},*/
		{ provide: OAuthStorage, useFactory: oAuthStorageFactory },

	],
	bootstrap: [AppComponent]
})
export class AppModule { }
