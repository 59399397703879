
import { Component, OnChanges, SimpleChanges, ViewEncapsulation, OnInit, NgZone } from '@angular/core';
import { Input } from '@angular/core';
import { WOption, WOptionEvent } from '@wipo/w-angular/shared';
import { menu_items } from 'src/app/_imports/menu';
import { MechanicsService } from 'src/app/_services/mechanics.service';
import { TranslateService } from '@ngx-translate/core';
import { retry } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'facets-search',
	templateUrl: './facets-search.component.html',
	styleUrls: ['./facets-search.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class CompFacetsSearch implements OnChanges {

	get windowWidth(): number {
		return window.innerWidth
	}

	public isCollapsed = false;
	constructor(
		public ms: MechanicsService,
		public router: Router,
		private ts: TranslateService,
		public ngZone: NgZone,
		public ar: ActivatedRoute
	) {
		const l = `Mneu constructor() -`
		this.buildMenu()
	}

	// rebuild when the input solrFacets change
	ngOnChanges(changes: SimpleChanges) {
		const l = `Mneu ngOnChanges -`
	
	}
	menuGroups = [];
	versions = [];

	buildMenu(): void {

		const l: string = `Mneu buildMenu() - `

		// First, building the facets that will be displayed in the left column
		this.versions = [];
		const allKeys = Object.keys(menu_items);
		
		for (const index in allKeys) {
			const variable = allKeys[+index]; // "applicant", "applicantCountryCode", "markFeature" etc. All facets
			this.versions.push(variable)
		}
		let current_version = this.ms.version || this.versions[this.versions.length-1]
		if (current_version != this.ms.version){
			this.ms.version = current_version
		}
		this.buildMenuVersions(menu_items[current_version])
	}

	buildMenuVersions(versioned_menu_items): void {

		const l: string = `Mneu buildMenu() - `

		// First, building the facets that will be displayed in the left column
		this.menuGroups = [];
		const allKeys = Object.keys(versioned_menu_items);
		console.log(allKeys)

		for (const index in allKeys) {

			const variable = allKeys[+index]; // "applicant", "applicantCountryCode", "markFeature" etc. All facets
			let toPush = {
				variable, // "office" in QueryParams, and "general_words.office" in translations
				collapsed: index !== "0",
				options: [],
				label: variable
			};
			this.menuGroups.push(toPush)
			for (let menu of this.menuGroups) { // now enriching it
				menu.options = versioned_menu_items[menu.label].map(filename => {
					let type = 'clients'
					let label:string;
					let value = filename;
					if (filename.includes('-')){
						label  = filename.split('-')[1];
					}
					else{
						label = filename
					}

					if (label.includes('.md')){
						label = label.replace('.md','')
					}
					if(!menu.label.includes('-')){
						type = 'docs'
					}
					else{
						value= (menu.label.split(' - ')[1]).toLowerCase() +'/'+ value
					}
					label = (label.split("_")).join(" ").toUpperCase()
					type = this.ms.version + '/' + type
					return {
						label,
						type,
						value, // Variable used in queryParams : "Pending" --> fcstatusPending
						lastSelected: false
					}
				})
	
			} // end for
	
		}
	}

	async changeVersion(version:string): Promise<void>{
		let url = this.ms.docs_to_load.replace(this.ms.version, version)
		window.location.href = url;
	}


	is_selected_version(version: string, ): boolean{
		return this.ms.version == version
	}

	is_selected(path: string, subpath: string): boolean{
		let url = this.ms.makeRoute({path, subpath})
		return this.ms.docs_to_load == url
	}
	
	async onSelect(path: string, subpath: string): Promise<void> {
		await new Promise(r => setTimeout(r))
		if (subpath.includes('.zip')){
			let fileURL = '/assets/' + path + '/' + subpath;
			window.open(fileURL, '_blank');
			return Promise.resolve()
		}
		
		this.ngZone.run(() => {

			let url = this.ms.makeRoute({path, subpath})
			this.ms.docs_to_load = url
			const navigateTo: string[] = url ? [url] : [];
			this.router.navigate(navigateTo, {
				relativeTo: this.ar,
				replaceUrl: true
			});
		}
		);
		return Promise.resolve()
	}

}

