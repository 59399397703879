export const menu_items = {
    "v1": {
        "Documentation": [
            "1-api_usage.md",
            "2-api_access.md",
            "3-image_classification.md",
            "4-image_search.md",
            "5-faq.md"
        ],
        "Client - Python": [
            "readme.md",
            "source.zip"
        ]
    }
}