import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { WOption } from '@wipo/w-angular/shared/w-option';

@Component({
	selector: 'page-notfound',
	templateUrl: './page-notfound.component.html',
	styleUrls: ['./page-notfound.component.css'],
	changeDetection: ChangeDetectionStrategy.Default, // OnPush --> blank page...
	encapsulation: ViewEncapsulation.None
})
export class PageNotFoundComponent implements OnInit {

	public main_menu: WOption[]

	constructor() { 
		let res = ['Documentations', 'Sample clients']
		this.main_menu = (res as any[])
				.map(s => {

					const value = s
				

					let toReturn = {
						value,
						label: value, // Short value
						score: 0,
						highlighted: null
					};


					return toReturn
				})
				.sort((a, b) => a.score > b.score ? -1 : 1)
	}

	ngOnInit(): void {
	}

}
